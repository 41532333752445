<template>
  <div class="flyOpretor">
    <div class="fly-title">北斗终端绑定无人机提交</div>
    <van-form>
      <van-field
        readonly
        clickable
        label="农服公司"
        :value="formData.companyName"
        placeholder="请选择公司名称"
        @click="showPicker = true"
        :rules="[{ required: true, message: '请选择公司名称' }]"
      />
      <van-field
        v-model="formData.pilotCode"
        label="飞控序列号"
        placeholder="请输入飞控序列号"
        :rules="[{ required: true, message: '请填写飞控序列号' }]"
      />
      <van-field
        v-model="formData.pilotTypeName"
        label="无人机型号"
        placeholder="请输入无人机型号"
        :rules="[{ required: true, message: '请填写无人机型号' }]"
      />
      <van-popup v-model="showPicker" round position="bottom">
        <van-search
          v-model="formData.searchName"
          @blur="searchNameFunc"
          placeholder="请输入搜索关键词"
        />
        <van-picker
          title="标题"
          show-toolbar
          :columns="projectListArr"
          @cancel="showPicker = false"
          @confirm="changeConfirm"
        />
      </van-popup>
      <!-- <van-field
        readonly
        clickable
        name="picker"
        :value="labelName"
        label="无人机型号"
        placeholder="点击选择无人机型号"
        :rules="[{ required: true, message: '请选择无人机型号' }]"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="labelList"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup> -->
      <van-field
        v-model="formData.beidouCode"
        label="北斗终端号"
        placeholder="请输入北斗终端号"
        :rules="[{ required: true, message: '请填写北斗终端号' }]"
      />
      <van-field
        v-model="formData.createBy"
        label="录入人姓名"
        placeholder="请输入录入人姓名"
        :rules="[{ required: true, message: '请填写录入人姓名' }]"
      />
      <van-field
        v-model="formData.phone"
        label="录入人手机号"
        placeholder="请输入录入人手机号"
        :rules="[{ required: true, message: '请填写录入人手机号' }]"
      />
      <van-field
        v-model="formData.recipientName"
        label="接收人姓名"
        placeholder="请输入接收人姓名"
        :rules="[{ required: true, message: '请填写接收人姓名' }]"
      />
      <van-field
        v-model="formData.recipientPhone"
        label="接收人手机号"
        placeholder="请输入接收人手机号"
        :rules="[{ required: true, message: '请填写接收人手机号' }]"
      />
      <van-field
        v-model="formData.manufacturingCode"
        label="出厂编号"
        placeholder="请输入出厂编号"
        :rules="[{ required: true, message: '请填写出厂编号' }]"
      />
      <van-field
        v-model="formData.manufacturers"
        label="出厂厂家"
        placeholder="请输入出厂厂家"
        :rules="[{ required: true, message: '请填写出厂厂家' }]"
      />
      <div class="addressWrap">
        <div class="address">地址</div>
        <div class="selectContent">
          <el-select
            @change="dealPlace(1)"
            v-model="formData.cityCode"
            style="width:90px"
            placeholder="市"
          >
            <el-option
              v-for="item in cityList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option> </el-select
          ><el-select v-model="formData.countyCode" placeholder="区/县" 
            style="width:90px"
          >
            <el-option
              v-for="item in areaList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div style="margin: 16px">
        <van-button round block type="info" @click="onSubmit">提交</van-button>
        <van-button
          style="margin-top: 10px"
          @click="onAddAgain"
          v-if="addAgain"
          round
          block
          type="primary"
          >再次添加</van-button
        >
        <van-button
          v-if="false"
          style="margin-top: 10px"
          @click="openCheck"
          round
          block
          type="danger"
          >查看终端</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { addBeidou } from "@/api/flyOpretor";
import { getDictData, getPlace, getCompanyName } from "@/api/serviechall";
export default {
  data() {
    return {
      formData: {
        pilotCode: "", //飞控序列号
        beidouCode: "", //北斗终端号
        createBy: "", //录入人姓名
        phone: "", //录入人手机号
        companyName: "", //公司名称
        // pilotType: "",
        pilotTypeName: "", //无人机型号
        recipientPhone: "", //接收人电话
        recipientName: "", //接收人姓名
        manufacturingCode: "", //出厂编号
        manufacturers: "", //出厂厂家
        searchName: "", //搜索字段
        cityCode: "",
        countyCode: "",
        townCode: "",
      },
      villageList: [],
      streetCity: [],
      areaList: [],
      cityList: [],
      pioList: [],
      projectListArr: [],
      showPicker: false,
      addAgain: false,
      labelName: "",
      labelList: [],
    };
  },
  methods: {
    onSubmit() {
      const {
        pilotCode,
        beidouCode,
        createBy,
        phone,
        pilotTypeName,
        recipientPhone,
        recipientName,
        manufacturingCode,
        manufacturers,
      } = this.formData;
      if (
        pilotCode == "" ||
        beidouCode == "" ||
        createBy == "" ||
        recipientName == "" ||
        pilotTypeName == "" ||
        manufacturingCode == "" ||
        manufacturers == ""
      ) {
        return;
      }
      let phoneReg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!phoneReg.test(phone)) {
        this.$toast("录入人手机号码不正确，请重新输入");
        return;
      }
      if (!phoneReg.test(recipientPhone)) {
        this.$toast("接收人手机号码不正确，请重新输入");
        return;
      }
      if(this.formData.cityCode === "" || this.formData.countyCode === ""){
        alert('请选择地址和区县')
        return 
      }
      addBeidou(this.formData).then((res) => {
        const { code, msg } = res;
        if (code === 200) {
          this.$toast("提交成功");
          this.addAgain = true;
        } else {
          this.$toast(msg || "操作失败");
        }
      });
    },
    getPlace() {
      const { provinceCode, cityCode, countyCode, townCode } = this.formData;
      let query = {
        areaCode: countyCode,
        provinceCode: "43",
        cityCode,
        streetCode: townCode,
      };
      getPlace(query).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.cityList = data.cityList;
          this.areaList = data.areaList ? data.areaList : [];
          this.streetCity = data.streetList ? data.streetList : [];
          this.villageList = data.villageList ? data.villageList : [];
        }
        // this.formData.countyName = ""
      });
    },
    getAreaStr() {
      this.cityList.map((item) => {
        if (item.code == this.formData.cityCode) {
          this.formData.city = item.name;
          return;
        }
      });
      this.areaList.map((item) => {
        if (item.code == this.formData.countyCode) {
          this.formData.county = item.name;
          return;
        }
      });
      this.streetCity.map((item) => {
        if (item.code == this.formData.townCode) {
          this.formData.town = item.name;
          return;
        }
      });
      this.villageList.map((item) => {
        if (item.code == this.formData.villageCode) {
          this.formData.village = item.name;
          return;
        }
      });
    },
    dealPlace() {
      this.getPlace();
    },
    onAddAgain() {
      let phone = this.formData.phone;
      let createBy = this.formData.createBy;
      Object.assign(this._data, this.$options.data());
      this.getDictData();
      this.formData.phone = phone;
      this.formData.createBy = createBy;
    },
    getDictData() {
      getDictData({ dictType: "sys_oms_pilot_type" }).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.pioList = data;
          this.labelList = data.map((item) => {
            return item.dictLabel;
          });
        }
      });
    },
    onConfirm(value, index) {
      this.labelName = value;
      this.formData.pilotType = this.pioList[index].dictValue;
      this.showPicker = false;
    },
    openCheck() {
      this.$router.push("/portal/viewTerminal");
    },
    searchNameFunc() {
      getCompanyName({ name: this.formData.searchName }).then((res) => {
        const { code, rows } = res;
        if (code === 200) {
          let newList = [];
          rows.forEach((item) => {
            newList.push({ text: item.name });
          });
          this.projectListArr = newList;
          console.log(newList);
        }
      });
    },
    changeConfirm(e) {
      this.formData.companyName = e.text;
      this.showPicker = false
    },
  },
  created() {
    this.getPlace();
    // this.getDictData();
  },
};
</script>
<style lang="scss" scoped>
.flyOpretor {
  .fly-title {
    width: 100%;
    text-align: center;
    padding: 14px 0;
    font-size: 20px;
    font-weight: bold;
  }
}
.addressWrap {
  display: flex;
  height: 50px;
  line-height: 50px;
  display: flex;
  .address{
    padding-left: 35px;
  }
  .selectContent{
    margin-left: 40px;
  }
}
</style>
