import request from '@/utils/request'

//新增北斗终端
export function addBeidou(data) {
  return request({
    url: '/oms/portal/addBeidou',
    method: 'post',
    data
  })
}

//查询北斗终端
export function getBeidou(data) {
  return request({
    url: '/oms/portal/getBeidou',
    method: 'post',
    data
  })
}

//删除北斗终端
export function deleteBeidou(ids) {
  return request({
    url: `/oms/portal/deleteBeidou/${ids}`,
    method: 'DELETE',
    // data: [ids]
  })
}